import React from 'react';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  ResponsiveContainer,
  CartesianGrid,
  Tooltip,
  LabelList,
} from 'recharts';
import { useTheme } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import Card from '@material-ui/core/Card';
import { makeStyles } from '@material-ui/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function CustomToolTip({ payload }) {
  if (!payload || !payload.length) return null;
  const {
    payload: { length, x0, x1 },
  } = payload[0];

  return (
    <Card
      style={{
        padding: '0.5rem',
      }}
    >
      <p>
        {length} fires within {x0} and {x1} frp range
      </p>
    </Card>
  );
}

const renderCustomizedLabel = ({ x, y, height, value }) => {
  return (
    <text
      x={x + 5}
      y={y + height / 2}
      fill="#000"
      dominantBaseline="middle"
      style={{
        fontSize: 14,
        fontWeight: 400,
      }}
    >
      {value}
    </text>
  );
};

renderCustomizedLabel.propTypes = {
  x: PropTypes.number,
  y: PropTypes.number,
  height: PropTypes.number,
  value: PropTypes.string,
};

const useStyles = makeStyles(theme => ({
  container: {
    overflow: 'visible',
  },
  noData: {
    textAlign: 'center',
    fontSize: 32,
    color: '#a9a9a9',
    fontWeight: 'lighter',
    top: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  disabled: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
  paper: {
    overflow: 'auto',
    marginLeft: '-93px',
    position: 'fixed',
    bottom: '40px',
    right: '14rem',
    left: '14rem',
    top: '10rem',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    paper: {
      bottom: '40px',
      right: '2.5rem',
      left: '2.5rem',
      top: '11rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    paper: {
      bottom: '40px',
      right: '0.5rem',
      left: '0.5rem',
      top: '14.5rem',
    },
  },
}));
CustomToolTip.propTypes = {
  payload: PropTypes.array,
};

function Chart({ data, loading, onStateClick }) {
  const classes = useStyles();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.between('xs', 'md'));

  function onClick(e) {
    onStateClick(e.activePayload[0].payload.name);
  }
  const stateData = data.slice();

  function sortData(stateData) {
    let data = stateData.sort((a, b) =>
      a.fires > b.fires ? -1 : b.fires > a.fires ? 1 : 0
    );
    return data.slice(0, 10);
  }

  if (!stateData || stateData.length <= 0) {
    return (
      <>
        <div className={`${classes.noData} ${loading ? classes.disabled : ''}`}>
          Not enough data
        </div>
      </>
    );
  }

  return (
    <div className={`${classes.paper} ${loading ? classes.disabled : ''}`}>
      <ResponsiveContainer
        width="100%"
        height="99%"
        className={classes.container}
      >
        <BarChart
          cursor="pointer"
          data={sortData(stateData)}
          layout="vertical"
          onClick={onClick}
          barSize={30}
          barGap={0}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis domain={[0, 'dataMax']} type="number" dataKey="fires" />
          <YAxis
            width={90}
            fill="#fff"
            type="category"
            dataKey="name"
            tick={!isMobile}
          />

          <Tooltip cursor={{ fill: 'none' }} />
          <Bar dataKey="fires" fill="#C0C0C0" isAnimationActive={false}>
            {isMobile && (
              <LabelList dataKey="name" content={renderCustomizedLabel} />
            )}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}

Chart.propTypes = {
  data: PropTypes.array.isRequired,
  loading: PropTypes.bool,
  onStateClick: PropTypes.func,
};

export default Chart;
