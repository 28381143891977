import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import moment from 'moment';
import { getFires } from '../api';
import Header from '../layout/header';
import DateTimePicker from '../components/home/datetime-picker';
import DurationSelector from '../components/home/duration-selector';
import DataTable from '../components/home/table';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Link from '@material-ui/core/Link';
import Paper from '@material-ui/core/Paper';
import Chart from '../components/home/graph';
import { addUrlProps, UrlQueryParamTypes } from 'react-url-query';
import PropTypes from 'prop-types';
import Loader from '../components/loader';
import { VisToggle } from '../components/home';
import {
  aggregateFireDataByKey,
  getDatesFromDuration,
  INDIA_SHAPE_ID,
} from '../utils';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import getIndiaStates from '../api/get-shapes';
dayjs.extend(utc);
dayjs.extend(customParseFormat);

const EARLIEST = '2015-01-01';

const useStyles = makeStyles(theme => ({
  root: {
    margin: '0.8rem 14rem 0',
  },
  topSection: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  displayVis: {
    display: 'flex',
    justifyContent: 'space-between',
    minWidth: '4rem',
    alignItems: 'center',
  },
  controls: {
    display: 'flex',
    width: '100%',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
  duration: {
    display: 'flex',

    alignItems: 'center',
    justifyContent: 'space-between',
  },
  states: {
    display: 'flex',
    height: 40,
  },
  footer: {
    position: 'fixed',
    width: '100%',
    bottom: 0,
    left: 0,
    fontSize: '1.2rem',
    textAlign: 'center',
    padding: '10px',
  },
  breadcrumb: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '0.4rem',
  },
  linkFont: {
    fontSize: 'inherit',
  },
  table: {
    border: '1px solid #9a9a9a',
    overflow: 'auto',
    position: 'fixed',
    bottom: '40px',
    right: '14rem',
    left: '14rem',
    top: '10rem',
  },
  [theme.breakpoints.between('xs', 'sm')]: {
    root: {
      margin: '1.5rem 2.5rem',
    },
    table: {
      bottom: '40px',
      right: '2.5rem',
      left: '2.5rem',
      top: '11rem',
    },
    topSection: {
      display: 'block',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      margin: '1rem 0.5rem',
    },
    table: {
      bottom: '40px',
      right: '0.5rem',
      left: '0.5rem',
      top: '14.5rem',
    },
    footer: {
      fontSize: '14px',
    },
    controls: {
      display: 'grid',
      gridTemplateAreas: `
      'date date'
      'duration duration'
      'breadcrumb displayVis'
      `,
    },
    breadcrumb: {
      gridArea: 'breadcrumb',
      justifyContent: 'center',
    },
    datePicker: {
      gridArea: 'date',
      display: 'flex',
      justifyContent: 'center',
    },
    duration: {
      gridArea: 'duration',
      justifyContent: 'center',
    },
    displayVis: {
      gridArea: 'displayVis',
      justifyContent: 'space-around',
    },
  },
}));

const urlPropsQueryConfig = {
  startDate: {
    type: UrlQueryParamTypes.string,
    defaultValue: dayjs.utc().subtract(1, 'month').format('DD-MM-YYYY'),
  },
  endDate: {
    type: UrlQueryParamTypes.string,
    defaultValue: dayjs().format('DD-MM-YYYY'),
  },
  state: { type: UrlQueryParamTypes.string, defaultValue: null },
  display: { type: UrlQueryParamTypes.string, defaultValue: 'table' },
};

function HomePage(props) {
  const classes = useStyles();
  const {
    startDate,
    onChangeStartDate,
    endDate,
    onChangeEndDate,
    display,
    onChangeDisplay,
    state,
    onChangeState,
  } = props;

  const [stateId, setStateId] = useState([]);
  const [stateFires, setStateFires] = useState([]);
  const [districtFires, setDistrictFires] = useState(null);
  const [loading, setLoading] = useState(true);
  const [duration, setDuration] = useState();

  //Only place where loading gets set
  useEffect(() => {
    if (
      !stateFires ||
      Boolean(state && !districtFires) ||
      stateFires.length < 1
    ) {
      setLoading(true);
    } else {
      setLoading(false);
    }
  }, [districtFires, state, stateFires]);

  //Get stateFires
  useEffect(() => {
    if (!startDate || !endDate) return;
    //set it to empty to start loader
    setStateFires([]);
    (async () => {
      try {
        setStateFires([]);
        const { data } = await getFires({
          startDate: dayjs(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          endDate: dayjs(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          shapeId: INDIA_SHAPE_ID,
          adminLevelBucket: 3,
          timeBucket: '1d',
        });

        const shapes = await getIndiaStates();
        const states = shapes.data.shape.features.map(({ properties }) => {
          const { name, shapeId } = properties;
          return { name, shapeId };
        });

        const stateData = aggregateFireDataByKey(data.data, 'name');
        setStateId(states);
        setStateFires(stateData);
        setDistrictFires(null);
      } catch (err) {
        setStateFires([]);
      }
    })();
  }, [startDate, endDate]);

  //Get district fires
  useEffect(() => {
    if (!startDate || !endDate || !state) {
      setDistrictFires(null);
      return;
    }

    //stateFires should be non empty array before getting district fire
    if (stateFires.length < 1) return;

    (async () => {
      try {
        //set it to null to start loader
        setDistrictFires(null);
        let stateData = stateFires.find(obj => {
          return obj.name === state.replace('-', ' ');
        });

        const districtResponse = await getFires({
          adminLevelBucket: 4,
          startDate: dayjs(startDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          endDate: dayjs(endDate, 'DD-MM-YYYY').format('YYYY-MM-DD'),
          shapeId: stateId.find(state => state.name === stateData.name).shapeId,
        });
        const districtData = aggregateFireDataByKey(
          districtResponse.data.data,
          'district'
        );
        setDistrictFires(districtData);
      } catch (e) {
        setDistrictFires(null);
      }
    })();
  }, [startDate, endDate, state, stateFires]);

  const onDateChange = ({
    startDate: selectedStartDate,
    endDate: selectedEndDate,
  }) => {
    onChangeStartDate(selectedStartDate.format('DD-MM-YYYY'));
    if (selectedEndDate.format('DD-MM-YYYY') === endDate)
      onChangeEndDate(selectedStartDate.format('DD-MM-YYYY'));
    else onChangeEndDate(selectedEndDate.format('DD-MM-YYYY'));
    setDuration(null);
  };

  const onDurationChange = (e, duration) => {
    const { startDate: start, endDate: end } = getDatesFromDuration(duration);
    setDuration(duration);
    onChangeEndDate(dayjs.utc(end).format('DD-MM-YYYY'));
    onChangeStartDate(dayjs.utc(start).format('DD-MM-YYYY'));
  };

  const onStateClick = name => {
    onChangeState(name.replace(' ', '-'));
  };

  return (
    <>
      <Header />
      <div className={classes.root}>
        <div className={classes.topSection}>
          <div className={classes.controls}>
            <div className={classes.datePicker}>
              <DateTimePicker
                earliest={dayjs.utc().subtract(5, 'year').format()} //Min date selection in date picker
                startDate={moment(startDate, 'DD-MM-YYYY')}
                endDate={moment(endDate, 'DD-MM-YYYY')}
                onDateChange={onDateChange}
              />
            </div>
            <div className={classes.breadcrumb}>
              <Paper elevation={0} className={classes.paper}>
                <Breadcrumbs separator=">" aria-label="breadcrumb">
                  <Link
                    className={classes.linkFont}
                    color="inherit"
                    href="#"
                    onClick={() => onChangeState(null)}
                  >
                    <strong>India</strong>
                  </Link>
                  {state && (
                    <p color="inherit">{state && state.replace('-', ' ')}</p>
                  )}
                </Breadcrumbs>
              </Paper>
            </div>

            <div className={classes.duration}>
              <DurationSelector
                duration={duration}
                onChange={onDurationChange}
                earliest={EARLIEST}
              />
            </div>
            <div className={classes.displayVis}>
              <VisToggle toggleVis={onChangeDisplay} vis={display} />
            </div>
          </div>
        </div>
        {loading ? (
          <Loader />
        ) : display === 'table' ? (
          <div className={classes.table}>
            <DataTable
              data={districtFires ? districtFires : stateFires}
              loading={loading}
              isState={Boolean(!districtFires)}
              onStateClick={districtFires ? () => {} : onStateClick}
            />
          </div>
        ) : (
          <Chart
            data={districtFires ? districtFires : stateFires}
            loading={loading}
            onStateClick={districtFires ? () => {} : onStateClick}
          ></Chart>
        )}
      </div>
    </>
  );
}

HomePage.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  state: PropTypes.string,
  display: PropTypes.string,
  onChangeStartDate: PropTypes.func.isRequired,
  onChangeEndDate: PropTypes.func.isRequired,
  onChangeState: PropTypes.func.isRequired,
  onChangeDisplay: PropTypes.func.isRequired,
};

export default addUrlProps({ urlPropsQueryConfig })(HomePage);
