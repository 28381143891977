const durations = [
  {
    label: '1M',
    title: '1 Month',
    value: '1m',
  },
  {
    label: '3M',
    title: '3 Months',
    value: '3m',
  },
  {
    label: '6M',
    title: '6 Months',
    value: '6m',
  },
  {
    label: '1Y',
    title: '1 Year',
    value: '1y',
  },
  {
    label: '3Y',
    title: '3 Years',
    value: '3y',
  },
];

export { durations };
