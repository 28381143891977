import React, { Component } from 'react';
import App from './app';

export default class Root extends Component {
  render() {
    return <App />;
  }
}

Root.propTypes = {};
