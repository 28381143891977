import React, { useState } from 'react';
import { DateRangePicker } from 'react-dates';
import { makeStyles } from '@material-ui/styles';
import PropTypes from 'prop-types';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    margin: '8px 0',
    marginLeft: -10,
    display: 'flex',

    '& .DateRangePicker': {
      display: 'flex',
      '& > div': {
        display: 'flex',

        '& > .DateRangePickerInput': {
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          border: 0,
        },
      },
    },

    '& .DateInput_input__focused': {
      borderBottom: '1px solid #fff',
    },
  },
}));
const DateTimePicker = ({ onDateChange, startDate, endDate, earliest }) => {
  const classes = useStyles();
  const [focused, setFocused] = useState(null);
  return (
    <div className={classes.root}>
      <DateRangePicker
        startDate={moment(startDate)}
        minDate={moment().set({
          year: moment(earliest).year(),
          month: moment(earliest).month() + 1,
        })}
        startDateId="date_from"
        endDate={moment(endDate)}
        endDateId="date_to"
        focusedInput={focused}
        hideKeyboardShortcutsPanel={true}
        onDatesChange={onDateChange}
        onFocusChange={focusedInput => setFocused(focusedInput)}
        isOutsideRange={day => {
          if (focused === 'endDate') {
            return (
              moment().diff(day, 'days') < 1 ||
              moment(day).diff(startDate, 'days') > 30 ||
              moment(day).diff(startDate, 'days') < 0
            );
          }

          return moment().diff(day, 'days') < 1;
        }}
      />
    </div>
  );
};

DateTimePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onDateChange: PropTypes.func.isRequired,
  earliest: PropTypes.string,
};

export default DateTimePicker;
