import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);
const DURATIONS = {
  '1m': {
    label: 'month',
    value: 1,
  },
  '3m': {
    label: 'month',
    value: 3,
  },
  '6m': {
    label: 'month',
    value: 6,
  },
  '1y': {
    label: 'year',
    value: 1,
  },
  '3y': {
    label: 'year',
    value: 3,
  },
  fe: {
    label: 'year',
    value: 5,
  },
};

const INDIA_SHAPE_ID = '5ac91f21-704b-4e91-ade4-541ba82aac7c';

const getDatesFromDuration = duration => {
  if (!DURATIONS[duration]) {
    return null;
  }

  //Date and time of now in IST
  const endDate = dayjs.utc().format();
  //startDate is 5 years minus from endDate when duration is 'fe'
  const startDate = dayjs
    .utc()
    .subtract(DURATIONS[duration].value, DURATIONS[duration].label)
    .format();

  return { startDate, endDate };
};

function aggregateFireDataByKey(data, key) {
  if (!data || !key) return data;
  const aggregateObject = data.reduce((acc, curr) => {
    const { fires, frp } = curr;
    if (!fires && !frp) return acc;
    const name = curr[key] || curr.name;
    if (acc[name]) {
      acc[name]['fires'] = acc[name]['fires'] + +fires;
      acc[name]['frp'] = acc[name]['frp'] + +frp;
      acc[name]['days'] = acc[name]['days'] + 1;
      return acc;
    } else {
      acc[name] = {};
      acc[name]['fires'] = +fires;
      acc[name]['frp'] = +frp;
      acc[name]['days'] = 1;
      return acc;
    }
  }, {});
  return Object.keys(aggregateObject).map(key => {
    const { fires, frp, days } = aggregateObject[key];
    return {
      name: key,
      fires,
      frp: +(frp / days).toFixed(2),
    };
  });
}

export { aggregateFireDataByKey, getDatesFromDuration, INDIA_SHAPE_ID };
