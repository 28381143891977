import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { primary } from '../styles/color.js';
// import FavoriteIcon from '@material-ui/icons/FavoriteBorder';
const useStyles = makeStyles(theme => ({
  root: {
    padding: '0 14rem',
  },
  super: {
    color: primary,
  },
  left: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  right: {
    paddingTop: 24,
  },
  title: {
    fontSize: '1.8rem',
    fontWeight: 600,
    marginBlockEnd: 0,
    marginBlockStart: 0,
  },
  subtitle: {
    fontSize: '1rem',
    marginBlockStart: 10,
  },
  content: {
    padding: '0.5rem 0rem',
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'underline',
    fontWeight: 500,
  },
  [theme.breakpoints.between('xs', 'md')]: {
    root: {
      padding: '0rem 0rem',
    },
    content: {
      padding: '0.5rem 2.5rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    root: {
      padding: '0rem 0rem',
    },
    content: {
      padding: '0.5rem 0.5rem',
    },
    subtitle: {
      fontSize: 10,
      marginBlockStart: 10,
    },
    right: {
      fontSize: 10,
    },
  },
}));

const Header = () => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <div className={classes.left}>
          <p className={classes.title}>
            Zuri
            <sup className={classes.super}> β </sup>
          </p>
          <p className={classes.subtitle}>Crop Fire Monitoring System</p>
        </div>
        <div className={classes.right}>
          <p>
            Powered by{' '}
            <a
              className={classes.link}
              href="https://blueskyhq.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Blue Sky Analytics
            </a>{' '}
            | More info on{' '}
            <a
              className={classes.link}
              href="http://spacetime.blueskyhq.in/"
              target="_blank"
              rel="noopener noreferrer"
            >
              SpaceTime
            </a>
          </p>
        </div>
      </div>
      <hr />
    </div>
  );
};

export default Header;
