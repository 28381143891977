import React from 'react';
import Link from '@material-ui/core/Link';
import Tooltip from '@material-ui/core/Tooltip';
import TableChartOutlinedIcon from '@material-ui/icons/TableChartOutlined';
import InsertChartOutlinedIcon from '@material-ui/icons/InsertChartOutlined';
import PropTypes from 'prop-types';

const VisToggle = ({ toggleVis, vis }) => {
  return (
    <>
      <Link
        style={
          vis === 'table'
            ? { marginTop: 4, marginLeft: 4, color: '#0000EE' }
            : { marginTop: 4, marginLeft: 4, color: '#000' }
        }
        color="inherit"
        href="#"
        onClick={() => toggleVis('table')}
      >
        <Tooltip title="Show table">
          <TableChartOutlinedIcon />
        </Tooltip>
      </Link>
      <Link
        style={
          vis === 'table'
            ? { marginTop: 4, marginLeft: 4, color: '#000' }
            : { marginTop: 4, marginLeft: 4, color: '#0000EE' }
        }
        color="inherit"
        href="#"
        onClick={() => toggleVis('graph')}
      >
        <Tooltip title="Show graph">
          <InsertChartOutlinedIcon />
        </Tooltip>
      </Link>
    </>
  );
};

VisToggle.propTypes = {
  toggleVis: PropTypes.func.isRequired,
  vis: PropTypes.string.isRequired,
};

export default VisToggle;
