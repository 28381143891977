import React from 'react';
import { makeStyles } from '@material-ui/styles';
import { Tabs, Tab } from '@material-ui/core';
import { durations as DURATIONS } from './config';
import PropTypes from 'prop-types';

const useStyles = makeStyles(theme => ({
  tabsRoot: {
    minHeight: 'unset',
    height: '40px',
  },
  tabsFixed: {
    display: 'flex',
  },
  tabRoot: {
    minWidth: 'unset',
    minHeight: 'unset',
    padding: '1rem',
    color: 'rgba(0,0,0,0.7)',
    '&.tabRootSelected': {
      color: '#000',
    },
    '&:first-child': {
      paddingLeft: 0,
    },
    '&:last-child': {
      paddingRight: 0,
    },
  },
  disable: {
    opacity: 0.5,
  },
  flexContainer: {
    justifyContent: 'space-between',
  },
  tabsIndicator: {
    backgroundColor: '#000',
    transition: 'all 600ms cubic-bezier(0.4,0,0.2,1) 0ms',
  },
  [theme.breakpoints.between('xs', 'md')]: {
    tabRoot: {
      padding: '1rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    tabRoot: {
      padding: '0.6rem',
    },
  },
}));

function DurationSelector({ duration, onChange }) {
  const classes = useStyles();
  return (
    <Tabs
      value={duration}
      indicatorColor="primary"
      textColor="primary"
      onChange={onChange}
      classes={{
        root: classes.tabsRoot,
        fixed: classes.tabsFixed,
        flexContainer: classes.flexContainer,
        indicator: classes.tabsIndicator,
      }}
      centered={true}
    >
      {DURATIONS.map(({ label, value, title }, index) => (
        <Tab
          classes={{
            root: classes.tabRoot,
            selected: 'tabRootSelected',
            disabled: classes.disable,
          }}
          disableRipple
          disableFocusRipple
          key={index}
          label={duration === value ? title : label}
          value={value}
        />
      ))}
    </Tabs>
  );
}

DurationSelector.propTypes = {
  duration: PropTypes.number,
  onChange: PropTypes.func,
  earliest: PropTypes.string,
};

export default DurationSelector;
