/* eslint-disable import/no-named-as-default */
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import HomePage from '../pages/home';
// import SupportPage from "../pages/support";
import NotFoundPage from '../pages/not-found';
import PropTypes from 'prop-types';
import React from 'react';
import { hot } from 'react-hot-loader';
import { ThemeProvider } from '@material-ui/core/styles';
import theme from './theme';
import { RouterToUrlQuery } from 'react-url-query';
import { __RouterContext as RouterContext } from 'react-router';

// This is a class-based component because the current
// version of hot reloading won't hot reload a stateless
// component at the top-level.
import { createBrowserHistory } from 'history';
const history = createBrowserHistory();

class App extends React.Component {
  render() {
    return (
      <ThemeProvider theme={theme}>
        <Router history={history}>
          <RouterToUrlQuery routerContext={RouterContext}>
            <Switch>
              <Route exact path="/" component={HomePage} />
              {/* <Route exact path="/" component={SupportPage} /> */}
              <Route component={NotFoundPage} />
            </Switch>
          </RouterToUrlQuery>
        </Router>
      </ThemeProvider>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default hot(module)(App);
