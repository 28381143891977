import React from 'react';
import PropTypes from 'prop-types';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import { makeStyles } from '@material-ui/styles';

const ASCENDING = 'asc';
const DESCENDING = 'desc';

function desc(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function stableSort(array, cmp) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = cmp(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

function getSorting(order, orderBy) {
  return order === DESCENDING
    ? (a, b) => desc(a, b, orderBy)
    : (a, b) => -desc(a, b, orderBy);
}

const headCells = isState => [
  {
    id: 'serial',
    label: 'S. No',
    sortable: false,
  },
  {
    id: 'name',
    label: isState ? 'State' : 'District',
    sortable: true,
  },
  {
    id: 'fires',
    label: 'Total Fire Count',
    sortable: true,
  },

  {
    id: 'frp',
    label: 'Avg. FRP',
    sortable: true,
  },
];

const headerStyles = makeStyles(theme => ({
  [theme.breakpoints.down('xs')]: {
    tableCell: {
      padding: 8,
      fontSize: 10,
    },
  },
}));

function EnhancedTableHead({ order, orderBy, onRequestSort, isState }) {
  const classes = headerStyles();
  return (
    <TableHead>
      <TableRow>
        {headCells(isState).map(({ id, label, sortable }, index) => (
          <TableCell
            key={index}
            align="left"
            className={classes.tableCell}
            sortDirection={orderBy === id ? order : false}
          >
            {sortable ? (
              <TableSortLabel
                active={orderBy === id}
                direction={order}
                onClick={() => onRequestSort(id)}
              >
                {label}
              </TableSortLabel>
            ) : (
              label
            )}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf([ASCENDING, DESCENDING]).isRequired,
  orderBy: PropTypes.string.isRequired,
  isState: PropTypes.bool,
};

const useStyles = makeStyles(theme => ({
  noData: {
    textAlign: 'center',
    fontSize: 32,
    color: '#a9a9a9',
    fontWeight: 'lighter',
    top: '50%',
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  disabled: {
    opacity: '0.7',
    pointerEvents: 'none',
  },
  paper: {
    overflow: 'auto',
    position: 'fixed',
    bottom: '40px',
    right: '14rem',
    left: '14rem',
    top: '11.5rem',
  },
  pointer: {
    cursor: 'pointer',
  },

  [theme.breakpoints.between('xs', 'md')]: {
    paper: {
      bottom: '40px',
      right: '2.5rem',
      left: '2.5rem',
      top: '12.5rem',
    },
  },
  [theme.breakpoints.down('xs')]: {
    paper: {
      bottom: '40px',
      right: '0.5rem',
      left: '0.5rem',
      top: '12.5rem',
    },
    tableCell: {
      padding: 8,
      fontSize: 10,
    },
  },
}));

function DataTable({ data: rawData, loading, isState, onStateClick }) {
  const classes = useStyles();
  const [order, setOrder] = React.useState(DESCENDING);
  const [orderBy, setOrderBy] = React.useState('fires');

  const handleRequestSort = property => {
    const isDesc = orderBy === property && order === DESCENDING;
    setOrder(isDesc ? ASCENDING : DESCENDING);
    setOrderBy(property);
  };

  if (!rawData || rawData.length <= 0) {
    return (
      <>
        <div className={`${classes.noData} ${loading ? classes.disabled : ''}`}>
          Not enough data
        </div>
      </>
    );
  }
  const data = rawData.map(val => ({
    ...val,
  }));
  return (
    <Table stickyHeader={true}>
      <EnhancedTableHead
        order={order}
        orderBy={orderBy}
        onRequestSort={handleRequestSort}
        isState={isState}
      />
      <TableBody>
        {stableSort(data, getSorting(order, orderBy)).map(
          ({ name, fires, frp }, index) => {
            const labelId = `enhanced-table-checkbox-${index}`;
            return (
              <TableRow key={index} hover tabIndex={-1}>
                <TableCell align="left">{index + 1}</TableCell>
                <TableCell
                  component="th"
                  id={labelId}
                  scope="row"
                  className={`${classes.pointer} ${classes.tableCell} `}
                  padding="default"
                  onClick={() => onStateClick(name)}
                >
                  {name}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {fires}
                </TableCell>
                <TableCell align="left" className={classes.tableCell}>
                  {frp}
                </TableCell>
              </TableRow>
            );
          }
        )}
      </TableBody>
    </Table>
  );
}

DataTable.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  isState: PropTypes.bool,
  onStateClick: PropTypes.func,
};

export default DataTable;
